import React, { Component, useState } from "react";
import styled, { keyframes } from "styled-components";
import { isMobile, isMobileOnly } from "react-device-detect";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Axios from "axios";

const VIDEOS = [
  {
    brand: "EDDIE BAUER",
    name: "PUFFER",
    price: "$180",
    src: "https://cdn.pixabay.com/photo/2017/07/31/11/38/cold-2557518_1280.jpg",
    left: 40,
    top: 0
  },
  {
    brand: "BURTON",
    name: "SURGENCE",
    price: "$200",
    src:
      "https://vod-progressive.akamaized.net/exp=1582675313~acl=%2A%2F1319574713.mp4%2A~hmac=24eca1f34b15381d5f9ce08bc3b1bbc0482dbedd7d29d18961f8c7c568c25d39/vimeo-prod-skyfire-std-us/01/1845/13/334228255/1319574713.mp4",
    left: -60,
    top: 0
  },
  {
    brand: "NUDIE JEANS",
    name: "DISTRESSED",
    price: "$80",
    src: "https://cdn.pixabay.com/photo/2017/10/13/07/50/youth-2847015_1280.jpg",
    left: 40,
    top: 0
  },
  {
    brand: "NORTH FACE",
    name: "STORM",
    price: "$90",
    src: "https://cdn.pixabay.com/photo/2017/08/02/18/22/people-2572754_1280.jpg",
    left: 0,
    top: 0
  },
  {
    brand: "DIESEL",
    name: "BLACKOUT",
    price: "$70",
    src: "https://cdn.pixabay.com/photo/2019/06/09/06/02/black-4261521_1280.jpg",
    left: 0,
    top: 0
  },
  {
    brand: "SANDQVIST",
    name: "STIG",
    price: "$80",
    src:
      "https://cdn.pixabay.com/photo/2017/08/06/21/34/people-2596578_1280.jpg",
    left: 90,
    top: 0
  },
  {
    brand: "NIKE",
    name: "TECH ZIP",
    price: "$75",
    src: "https://cdn.pixabay.com/photo/2017/10/11/19/51/abs-2842208_1280.jpg",
    left: 60,
    top: 0
  },
  {
    brand: "RANDOM",
    name: "RONDA",
    price: "$60",
    src: "https://cdn.pixabay.com/photo/2015/10/12/14/50/shorts-983901_1280.jpg",
    left: 20,
    top: 0
  },
  {
    brand: "CONVERSE",
    name: "CHUCK HIGH",
    price: "$50",
    src: "https://cdn.pixabay.com/photo/2016/08/22/10/04/traveler-1611614_1280.jpg",
    left: 70,
    top: 30
  },
  {
    brand: "CLO",
    name: "BEIGE",
    price: "$50",
    src: "https://cdn.pixabay.com/photo/2016/04/04/15/30/girl-1307429_1280.jpg",
    left: 40,
    top: 0
  },
  {
    brand: "NIKE",
    name: "AIR ZOOM",
    price: "$80",
    src: "https://cdn.pixabay.com/photo/2016/11/21/15/54/countryside-1846093_1280.jpg",
    left: 50,
    top: 0
  }

];

// const VIDEOS = [
//   {
//     brand: "NIKE",
//     name: "Infinity",
//     price: "$200",
//     src:
//       "https://c.static-nike.com/a/videos/q_90/7976053b-4853-4a47-8bd0-2958aa3aae02/video.mp4"
//   },
//   {
//     brand: "NIKE",
//     name: "Air Max",
//     price: "$85",
//     src:
//       "https://c.static-nike.com/a/videos/q_90/xozo5zhiypjmww8mgxsk/video.mp4"
//   }
//   // {
//   //   brand: "ADIDAS",
//   //   name: "Z.N.E",
//   //   price: "$140",
//   //   src:
//   //     "https://assets.adidas.com/images/w_840,h_840,f_auto,q_auto:sensitive,fl_lossy/2c8809782d2b4e7e9442ab030104c778_9366/adidas_Z.N.E._Hoodie_Red_FL1958_21_model.jpg"
//   // }
// ];

const video = VIDEOS[Math.floor(Math.random() * Math.floor(VIDEOS.length))];

const S = {};

S.App = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  padding ${isMobile ? 24 : 80}px;
  background: black;
  box-sizing: border-box;
`;

S.WhiteCover = styled.div`
  position: absolute;
  width: 650px;
  height: 100%;
  background: white;
  top: 0;
  right: 0;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 20% 100%);
`;

S.BoxLogo = styled.img`
  height: ${isMobileOnly ? 40 : 60}px;
  width: ${isMobileOnly ? 40 : 60}px;
`;

S.HeroText = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  color: white;
  font-size: ${isMobileOnly ? 34 : 60}px;
  margin-top: ${isMobileOnly ? 20 : 30}px;
`;

S.Description = styled.div`
  font-family: "Open Sans";
  font-weight: 500;
  color: white;
  flex-grow: 1;
  overflow: auto;
  width: ${isMobile ? 100 : 50}%;
  font-size: ${isMobile ? 16 : 24}px;
  margin-top: ${isMobile ? 15 : 30}px;
`;

S.ComingSoon = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  color: white;
  font-size: ${isMobileOnly ? 20 : 30}px;
  margin-top: ${isMobile ? 15 : 30}px;
`;

S.EmailInput = styled.input`
  height: ${isMobileOnly ? 56 : 72}px;
  color: white;
  border: none;
  border-left: 8px solid white;
  background: black;
  width: ${isMobile ? 80 : 50}%;
  margin: ${isMobileOnly ? 32 : 56}px 0px;
  font-size: ${isMobileOnly ? 24 : 32}px;
  padding: 0px 30px;
  font-family: Oswald;
  font-weight: 600;

  &::-webkit-input-placeholder {
    color: #333;
    font-family: Oswald;
    font-weight: 600;
  }

  &:focus {
    outline: none;
    background: #111;
  }
`;

S.Inquiries = styled.div`
  position: absolute;
  font-family: Montserrat;
  font-weight: 600;
  color: white;
  font-size: ${isMobile ? 16 : 20}px;
  margin-top: ${isMobile ? 140 : 10}px;
`;

S.PhoneContainer = styled.div`
  position: absolute;
  height: 700px;
  width: 375px;
  right: 80px;
  top: calc((100% - 700px) / 2);
  perspective: 600px;
`;
S.Phone = styled.div`
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  border-radius: ${isMobileOnly ? 24 : 32}px;
  background: black;
  transition: transform 0.1s;
  overflow: hidden;
`;

S.PhoneButton = styled.div`
  position: absolute;
  bottom: 50px;
  left: 50px;
  right: 50px;
  height: 56px;
  border-radius: 28px;
  background: #ff6847;
  box-shadow: 0px 4px 0px 0px #d04c2f;

  transition: all 0.1s;
  text-align: center;
  line-height: 56px;
  font-family: Oswald;
  font-weight: bold;

  &:hover {
    box-shadow: 0px 8px 0px 0px #d04c2f;
    cursor: pointer;
  }
  &:active {
    box-shadow: 0px 2px 0px 0px #d04c2f;
  }
`;

/**
 *
 */

S.PhoneLogo = styled.img`
  position: absolute;
  width: 100%;
  top: 35px;
  height: 20px;
`;

S.PhoneMenu = styled.img`
  position: absolute;
  left: 28px;
  top: 35px;
  height: 20px;
`;

S.PhoneLightning = styled.img`
  position: absolute;
  right: 28px;
  top: 35px;
  height: 20px;
`;

function BoxLogo({}) {
  return <S.BoxLogo src="images/ds_box.svg" />;
}
function PhoneContainer({}) {
  const [mouseOver, setMouseOver] = useState(false);
  const [rotatePos, setRotatePos] = useState({ x: 0, y: 0 });

  return (
    <S.PhoneContainer
      onMouseOverCapture={() => setMouseOver(true)}
      onMouseLeave={() => {
        setMouseOver(false);
        setRotatePos({ x: 0, y: 0 });
      }}
      onMouseMove={e => {
        const elementPosition = e.currentTarget.getBoundingClientRect();
        const { screenX, screenY } = e;
        const { width, height } = elementPosition;
        const relX = screenX - elementPosition.x;
        const relY = screenY - elementPosition.y;

        const offsetX = relX - width / 2;
        const offsetY = relY - height / 2;

        setRotatePos({
          x: offsetX / (width / 2),
          y: offsetY / (height / 2)
        });
      }}
    >
      <Phone rotatePos={rotatePos} mouseOver={mouseOver} />
    </S.PhoneContainer>
  );
}

function Phone({ rotatePos, mouseOver }) {
  return (
    <S.Phone
      style={{
        boxShadow: `${-8 * rotatePos.x}px ${8 -
          14 *
            rotatePos.y}px 0px 0px black, 0px 24px 32px 0px rgba(0, 0, 0, 0.3)`,
        transform: `rotateY(${mouseOver ? 6 * rotatePos.x : 0}deg) rotateX(${
          mouseOver ? -6 * rotatePos.y : 0
        }deg)`
      }}
    >
      {true && (
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            left: 0,
            top: 0
          }}
        >
          {video.src.includes("mp4") ? (
            <video
              autoplay="true"
              muted="true"
              loop="true"
              playsInline="true"
              height="100%"
              style={{
                position: "absolute",
                left: `${video.left}%`,
                top: `${video.top}%`
              }}
            >
              <source src={video.src} type="video/mp4" />
            </video>
          ) : (
            <div
              style={{
                backgroundImage: `url(${video.src})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: `${video.left}% ${video.top}%`,
                backgroundSize: "auto 100%",

                position: "absolute",
                height: "100%",
                width: "100%",
                left: 0,
                top: 0
              }}
              alt="Example Product"
            />
          )}

          {true && (
            <>
              <div
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  width: "100%",
                  height: "20%",
                  background:
                    "linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%)"
                }}
              />
              <div
                style={{
                  position: "absolute",
                  left: 0,
                  bottom: 0,
                  width: "100%",
                  height: "50%",
                  background:
                    "linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%)"
                }}
              />
            </>
          )}

          {isMobile && (
            <div
              style={{
                position: "absolute",
                left: 0,
                bottom: 0,
                width: "100%",
                height: "100%",
                background: "rgba(0,0,0,0.3)"
              }}
            />
          )}

          {!isMobile && (
            <div
              style={{
                position: "absolute",
                left: 0,
                margin: "0px 56px",
                bottom: "70px",
                width: "100%",
                height: "20%",
                fontFamily: "Oswald"
              }}
            >
              <div style={{ fontSize: 30, lineHeight: "15px" }}>
                {video.brand}
              </div>
              <div style={{ fontSize: 60 }}>{video.name}</div>
            </div>
          )}
        </div>
      )}
      {!isMobile && <S.PhoneMenu src="images/menu.svg" />}
      {!isMobile && <S.PhoneLogo src="images/logo.svg" />}
      {!isMobile && <S.PhoneLightning src="images/lightning.svg" />}

      {isMobile && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            margin: 28,
            height: "calc(100% - 70px)",
            width: isMobile ? "none" : "calc(100% - 112px)",
            boxSizing: "border-box"
          }}
        >
          <BoxLogo />
          <Splash />
        </div>
      )}
      {!isMobile && <S.PhoneButton>{video.price}</S.PhoneButton>}
    </S.Phone>
  );
}

function Splash({}) {
  return (
    <>
      <S.HeroText>
        Hello,
        {!isMobileOnly && <br />} Good Buy.
      </S.HeroText>

      <Link to="/waitlist" style={{ color: "white" }}>
        <S.ComingSoon>join our waitlist.</S.ComingSoon>
      </Link>

      <S.Description>
        Dropshop is e-commerce with a twist: you can only see each item once.
        <div style={{ display: "block", marginTop: 20 }} />
        We use this to get you the best deals on the internet, directly from
        brands, all in a personalized feed for you to discover and love.
        <div style={{ display: "block", marginTop: 20 }} />
        See something you like? It's yours with a single tap. No forms, menus,
        surprise fees, forgetting your postal code, or looking for your wallet.
        Interested?
      </S.Description>
      {/* <S.EmailInput placeholder="Email Address" /> */}

      {/* <S.Inquiries>
        Inquiries? Drop an email to Aidan or Jay @getdrop.shop
      </S.Inquiries> */}
    </>
  );
}

const Landing = ({}) => {
  if (isMobile) {
    return (
      <S.App>
        <Phone rotatePos={{ x: 0, y: 0 }} />
      </S.App>
    );
  }
  return (
    <S.App>
      <div style={{overflow: "auto"}}>
        <BoxLogo />
        <Splash />
      </div>

      <S.WhiteCover></S.WhiteCover>
      <PhoneContainer />
    </S.App>
  );
};

const Emails = ({}) => {
  const [isDone, setIsDone] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);

  return (
    <S.App>
      <div style={{ margin: 16 }}>
        <div>
          <BoxLogo />
        </div>
        {isDone ? (
          <S.HeroText>You're all set.</S.HeroText>
        ) : (
          <>
            <S.HeroText>Welcome to the club.</S.HeroText>
            {error && <S.ComingSoon>{error}</S.ComingSoon>}
            <S.EmailInput
              placeholder="Email Address"
              onChange={e => setEmail(e.currentTarget.value)}
            />
            <div
              style={{ position: "relative", top: 100, width: 300, left: -50 }}
            >
              <S.PhoneButton
                onClick={() => {
                  setLoading(true);
                  Axios.post("https://presignup-server.herokuapp.com/user", {
                    email
                  })
                    .then(() => {
                      setIsDone(true);
                      setTimeout(() => (window.location.href = "/"), 1000);
                    })
                    .catch(err => {
                      console.log(err.response);
                      setLoading(false);
                      if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                      ) {
                        setError(err.response.data.message);
                      } else {
                        setError("Sorry, something went wrong.");
                      }
                    });
                }}
              >
                {loading ? "..." : "Join our waitlist"}
              </S.PhoneButton>
            </div>
          </>
        )}
      </div>
    </S.App>
  );
};

class App extends Component {
  render() {
    return (
      <Router>
        <Route exact path="/" component={Landing} />
        <Route path="/waitlist" component={Emails} />
      </Router>
    );
  }
}

export default App;
